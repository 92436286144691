import React from 'react'
import imageOwn from "./icons/owner.jpeg"
import './HeroSection.css';
function Home() {
        return (
                <div className='hero-container' id='home'>
                        {/* <div className='home-div'>
                           
                        </div> */}
                        {/* <div  style={{margin:"85vh"}}>

                        </div> */}
                </div>
        )
}

export default Home